
<template>
  <div id="user-edit-tab-info">
    <h6 class="mb-3 text-uppercase bg-light p-2">
        <i class="mdi mdi-account-circle mr-1"></i> Other Info
    </h6>
    <div class="row gy-3 gx-3">
        <div class="col-md-6">
          <div class="form-group">
              <label class="d-block">Date of Birth</label>
              <flat-pickr
                v-model="data.dob"
                :config="{wrap: true, 
                  altFormat: 'M j, Y',
                  altInput: true,
                  dateFormat: 'd M, Y',
                  maxDate: new Date()
                }"
                class="form-control flatpickr-input"
              ></flat-pickr>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Country</label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="countries" 
            v-model="data.country_id" placeholder="--select--"></multiselect>
          </div>
        </div>
        <div class="col-12">
            <multiselect 
              mode="tags"
              :close-on-select="false"
              searchable
              valueProp="id"
              trackBy="name" label="name"
              :options="categories" 
              v-model="data.categories" placeholder="--select category--"></multiselect>
        </div>
        <div class="col-12">
          <label for="userbio">About</label>
          <textarea id="userbio"  v-model="data.about" class="form-control"
            rows="3" placeholder="Write something..." name="about"></textarea>
        </div>

        <div class="col-md-6">
          <label for="social-fb">Facebook</label>
          <div class="input-group">
              <span class="input-group-text">
                  <i class="ri ri-facebook-circle-line"></i>
              </span>
              <input v-model="data.facebook" id="social-fb" type="text" class="form-control" placeholder="Url" name="facebook" />
          </div>
        </div>
        <div class="col-md-6">
          <label for="social-tw">Twitter</label>
          <div class="input-group">
              <span class="input-group-text">
                  <i class="ri ri-twitter-line"></i>
              </span>
              <input  v-model="data.twitter" id="social-tw" type="text" class="form-control" placeholder="url" name="twitter"  />
          </div>
        </div>
        <div class="col-md-6">
          <label for="social-insta">Instagram</label>
          <div class="input-group">
              <span class="input-group-text">
                  <i class="ri ri-instagram-line"></i>
              </span>
              <input v-model="data.instagram" id="social-insta" type="text" class="form-control" placeholder="Url" name="instagram"/>
          </div>
        </div>
        <div class="col-md-6">
          <label for="social-gh">Github</label>
          <div class="input-group">
                <span class="input-group-text">
                    <i class="ri ri-github-line"></i>
                </span>
              <input  v-model="data.github" id="social-gh" type="text" class="form-control" placeholder="url" name="github" />
          </div>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import flatPickr from "vue-flatpickr-component";
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    flatPickr,
    Multiselect,
  },
  data() {
    return {
      data:{},
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
    countries(){
      return this.$store.state.countries;
    },
    categories(){
      return this.$store.state.categories;
    },
  },
  methods: {
    updateUser(){
      const formData = new FormData()
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post("/account/update-info", formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit('UPDATE_USER_INFO', response.data.data.user)
          }
      })
    },
    patchUser(){
      this.data = {
        about: this.activeUser.about,
        dob: this.activeUser.dob ? new Date(this.activeUser.dob) : null,
        gender: this.activeUser.gender,
        country_id: this.activeUser.country_id,
        categories: this.activeUser.categoryIds 
                    ?  this.activeUser.categoryIds.map(c => c.category_id) : [],
        twitter: this.activeUser.twitter,
        facebook: this.activeUser.facebook,
        instagram: this.activeUser.instagram,
        github: this.activeUser.github,
      }
    }
  },
  created(){
    this.patchUser()
    this.$store.dispatch("fetchCategories")
  },
}
</script>
